import React, { useState } from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "./TextImageBlock"
import PressBlock from "../Blocks/PressBlock"
import Image from "src/components/Blocks/Image"
import { Col, Row } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import breakpoints from "src/styles/breakpoints"


const ClockCol = styled(Col)`
  width: auto;
`
const H2 = styled.h2`
  color: white; 
  line-height: 100%; 

  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.md})
  {
    display: flex; 
    justify-content: center; 
  }
`
const CountdownDiv = styled.div`
  text-align: left;
  margin-left: 5vw;
  margin-right: 3vw;
  ${'' /* margin-top: 25%; */}
  @media (max-width: ${breakpoints.md}) {
      margin-top: auto;
    
  }
`
const ClockRow = styled(Row)`
  justify-content: center;
`

const UnitTitle = styled.div`
  font-size: 14px;
  letter-spacing: initial;
  color: white;
`

const CountdownTimer = styled.div`
  justify-content: left;
  display: flex;
  padding-top: 25px;
  flex-wrap: wrap;
  letter-spacing: 0.2rem;
  ${'' /* margin-bottom: 4rem; */}
  @media (max-width: ${breakpoints.md}) {
      margin-top: 2vw;
      justify-content: center;
  }
`
const Break = styled.div`
  flex-basis: 100%;
  height: 0px;
`
const Colon = styled.div`
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
  background-color: transparent;
  text-align: center;
  @media (max-width: 976px) {
 
    font-size: 40px;
    line-height: 40px;
  }
`

const Days = styled.div`
  border-radius: 5px;
  font-size: 50px;
  background-color: #ffffff;
  color: #eb9cc3;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
  display: flex;
  height: 65px;
  line-height: 60px;
  text-align: center;
  width: 40px;
  justify-content: center;
  @media (max-width: 976px) {
    width: 30px;
    font-size: 30px;
    height: 40px;
    line-height: 40px;

  }
`

const Hours = styled.div`
  border-radius: 5px;
  font-size: 50px;
  background-color: #ffffff;
  color: #dccb49;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
  display: flex;
  width: 40px;
  justify-content: center;
  height: 65px;
  line-height: 60px;
  text-align: center;
  @media (max-width: 976px) {
    width: 30px;
    font-size: 30px;
    height: 40px;
    line-height: 40px;

  }
`

const Minutes = styled.div`
  border-radius: 5px;
  font-size: 50px;
  background-color: #ffffff;
  color: #319ad5;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
  display: flex;
  height: 65px;
  line-height: 60px;
  text-align: center;

  width: 40px;
  justify-content: center;
  @media (max-width: 976px) {
    width: 30px;
    font-size: 30px;
    line-height: 40px;

    height: 40px;
  }
`

const Seconds = styled.div`
  border-radius: 5px;
  font-size: 50px;
  background-color: #ffffff;
  color: #6c935f;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
  display: flex;
  width: 40px;
  justify-content: center;
  height: 65px;
  line-height: 60px;
  text-align: center;
  @media (max-width: 976px) {
    width: 30px;
    font-size: 30px;
    line-height: 40px;

    height: 40px;
  }
`
const CountdownContainer = styled.div`
  height: auto;
  background-color: #07408a;
`

const Heading = styled.h2`
  ${'' /* margin-left: 10vw;
  
  margin-right: 10vw; */}
 font-weight: 900;
  @media (min-width: 1600px){

    font-size: 60px;

  }

  font-size: 5vw;
  width: 80%;
  text-transform: uppercase;
  line-height: 4vw;
  color: #ffffff;
  line-height: 100%;
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    justify-content: center;
    font-size: 6vw;
    line-height: 6vw;
    width: auto;
}
`

const SubHeading = styled.h2`
  ${'' /* margin-left: 10vw;
  margin-right: 10vw; */}
  font-weight: 900;
  line-height: 100%;
  @media (min-width: 1600px){

    font-size: 40px;

}
font-size: 3vw;
  ${'' /* margin-top: 6vw; */}
  margin-top: 10%;
  text-transform: uppercase;
  color: #eb9cc3;
  line-height: 3vw;
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    justify-content: center;
    font-size: 4vw;
    margin-bottom: 6vw;
}
`

const HeadingDiv = styled.div`
 text-align: left;
`

const LeftCol = styled(Col)`
  height: 100%;
`

const CoolRow = styled(Row)`
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
`
const RightCol = styled(Col)`
  height: 100%;
`
const CountdownBlock = (props) => {
  const [isCountingDown, setIsCountingDown] = useState(
    props.countdownDateTime ? true : false
  )
  let countdownDateTime = props.countdownDateTime
  let countDownDate
  if (countdownDateTime) {
    // Set the date we're counting down to
    countDownDate = new Date(countdownDateTime).getTime()

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      if (days.toString().length == 1) {
        days = "0" + days
      }
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      if (hours.toString().length == 1) {
        hours = "0" + hours
      }
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      if (minutes.toString().length == 1) {
        minutes = "0" + minutes
      }
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)
      if (seconds.toString().length == 1) {
        seconds = "0" + seconds
      }

      // Output the result in an element with id="countdownTimer"
      // document.getElementById("countdownTimer").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

      try {
        document.getElementById("days1").innerHTML = days.toString()[0]
        document.getElementById("days2").innerHTML = days.toString()[1]
        document.getElementById("hours1").innerHTML = hours.toString()[0]
        document.getElementById("hours2").innerHTML = hours.toString()[1]
        document.getElementById("minutes1").innerHTML = minutes.toString()[0]
        document.getElementById("minutes2").innerHTML = minutes.toString()[1]
        document.getElementById("seconds1").innerHTML = seconds.toString()[0]
        document.getElementById("seconds2").innerHTML = seconds.toString()[1]
      } catch (e) {
        // console.log(e)
      }

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x)
        setIsCountingDown(false)
        try {
          document.getElementById("days1").innerHTML = 0
          document.getElementById("days2").innerHTML = 0
          document.getElementById("hours1").innerHTML = 0
          document.getElementById("hours2").innerHTML = 0
          document.getElementById("minutes1").innerHTML = 0
          document.getElementById("minutes2").innerHTML = 0
          document.getElementById("seconds1").innerHTML = 0
          document.getElementById("seconds2").innerHTML = 0
        } catch (e) {
          // console.log(e)
        }
        // document.getElementById("countdownTimer").innerHTML = "MEALS AVAILABLE NOW!";
      } else {
        setIsCountingDown(true)
      }
    }, 1000)
  }
  return (
    <CountdownContainer>
      <CoolRow>
        <Col md={6}>
          <Image image={props.chefImage} alt={"Chef"} />
        </Col>
        <RightCol md={6}>
          
        <CountdownDiv>
         
          <SubHeading>{props.subHeading}</SubHeading>

          
            <Heading> {props.heading} </Heading>
     
          <CountdownTimer>
      
                <ClockCol>
                  <ClockRow>
                    <Days id={"days1"}></Days>
                    <Days id={"days2"}></Days>
                  </ClockRow>
                  <ClockRow>
                    <UnitTitle>days</UnitTitle>
                  </ClockRow>
                </ClockCol>
                <ClockCol>
                  <ClockRow>
                    <Colon>:</Colon>
                  </ClockRow>
                  <ClockRow></ClockRow>
                </ClockCol>
                <ClockCol>
                  <ClockRow>
                    <Hours id={"hours1"}></Hours>
                    <Hours id={"hours2"}></Hours>
                  </ClockRow>
                  <ClockRow>
                    <UnitTitle>hours</UnitTitle>
                  </ClockRow>
                </ClockCol>
                <ClockCol>
                  <ClockRow>
                    <Colon>:</Colon>
                  </ClockRow>
                  <ClockRow></ClockRow>
                </ClockCol>
                <ClockCol>
                  <ClockRow>
                    <Minutes id={"minutes1"}></Minutes>
                    <Minutes id={"minutes2"}></Minutes>
                  </ClockRow>

                  <ClockRow>
                    <UnitTitle>minutes</UnitTitle>
                  </ClockRow>
                </ClockCol>
                <ClockCol>
                  <ClockRow>
                    <Colon>:</Colon>
                  </ClockRow>
                  <ClockRow></ClockRow>
                </ClockCol>
                <ClockCol>
                  <ClockRow>
                    <Seconds id={"seconds1"}></Seconds>
                    <Seconds id={"seconds2"}></Seconds>
                  </ClockRow>

                  <ClockRow>
                    <UnitTitle>seconds</UnitTitle>
                  </ClockRow>
                </ClockCol>
                <Break></Break>
  
        
            
       
          </CountdownTimer>
          { !isCountingDown ? 
              <H2 >New Dishes Are Here!</H2>
             : ""}
          </CountdownDiv>
        
        </RightCol>
      </CoolRow>
    </CountdownContainer>
  )
}
export default CountdownBlock
