import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import CtaButton from "../Core/CtaButton"
import Text from "./Text"
import { useStaticQuery } from "gatsby"
import ProductLandingPage from "src/components/Index/ProductLandingPageBlock"

const BlockContainer = styled.div`
   padding-top: 25px;
   padding-bottom: 25px;
`

const HomePageShop = (props) => {
    const { shopHeading, shopSubHeading, contentfulProducts } = props
    ; 
    // const slugs = contentfulProducts.map(x=>x.slug);
    // let newSlugs = "[";
    // for (const slug of slugs)
    // {
    //     newSlugs += "\""+slug+"\"";
    //     newSlugs+=","
    // }
    // newSlugs = newSlugs.slice(0, -1);
    // newSlugs += "]"; 
    // const swellProduct = useStaticQuery(graphql`
    //     query SwellProducts{
            // allSwellProduct(filter: {slug: {in: ["nolita-ribs", "jame-ospi-lasagna", "holy-basil-curry"]} }) {
            //     edges {
            //       node {
            //         slug
            //       }
            //     }
            //   }
            // }
    // `);
    return (
     <BlockContainer>
    
     </BlockContainer>
    )
}

export default HomePageShop