import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import CtaButton from "../Core/CtaButton"
import Text from "../Blocks/Text"
import { Link } from "gatsby"

const InternalLink = styled(Link)`
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));

  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 0.4em 1.8em;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  cursor: pointer;
  display: inline-block;
  background: #319ad5;
  border-color: #319ad5;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 18px;

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 20px;
  }
`

const KlaviyoButton = styled.button`
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));

  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 0.4em 1.8em;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  cursor: pointer;
  display: inline-block;
  background: #319ad5;
  border-color: #319ad5;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 18px;

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 20px;
  }
`
const Icon = styled.img`
  position: absolute;
  width: 125px;
  top: 37%;
  left: -10%;

  @media (max-width: ${breakpoints.md}) {
    width: 100px;
    top: 86%;
    left: -6%;
    width: 75px;
  }
`
const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`
const BlockContainer = styled.div`
  padding: 5vw;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
`

const Background = styled.div`
  padding: 6.6vmax 6vw;
  background: ${(props) =>
    props.bg === "Blue"
      ? colors.royalBlue
      : props.bg === "Pink"
      ? colors.pink
      : colors.lightGrey};
`

const ContentContainer = styled.div`
  padding: 0 4%;
`

const Heading = styled.h2`
  color: ${(props) => (props.bg === "White" ? colors.royalBlue : colors.white)};
  line-height: 1.5;
  margin-top: calc(-1em - 1.5%);
  position: relative;
  z-index: 1;
  display: block;
  font-size: 15rem;
  span {
    background: ${(props) =>
      props.highlight === "Blue"
        ? colors.royalBlue
        : props.highlight === "Yellow"
        ? colors.yellow
        : "transparent"};
    webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0 3%;
  }

  @media (min-width: ${breakpoints.lg}) {
  }
`

const Content = styled.div`
  margin-top: 18px;
  color: ${(props) => (props.bg === "White" ? colors.black : colors.white)};

  p {
    margin-bottom: 1rem;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    ${fonts.workSansBold};
  }
`

const ButtonContainer = styled.div`
  margin-top: 17px;
`

const StyledRow = styled(Row)`
  position: relative;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
`

const Rectangle = styled.div`
  width: calc(40vw + 1em);
  ${"" /* width: 450px; */}
  height: auto;
  background-color: white;
  border-radius: 50px;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  text-align: center;
  @media (max-width: ${breakpoints.md}) {
    width: 90vw;
  }
`

const Title = styled.h2`
  font-family: "Work Sans";
  font-style: normal;
  text-transform: uppercase;
  color: ${colors.royalBlue};
  font-size: calc(2.4vw + 0.5rem);
  padding-top: 0.2rem;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 1vw;
  line-height: 100%;

  ${"" /* line-height: 2rem; */}
  @media (max-width: ${breakpoints.md}) {
    font-size: calc(2.4vw + 16px);
    margin-top: 12px;
    margin-bottom: 8px;
  }
`
const SubTitle = styled.h4`
  font-family: "Work Sans";
  font-style: normal;
  font-size: 1.2rem;
  color: ${colors.royalBlue};
`

const ExplainDetail = styled.h5`
  font-family: "Work Sans";
  font-style: normal;
  font-size: calc(1.5vw + 0.5rem);
  padding: 0.2rem;
  line-height: 100%;
  @media (max-width: ${breakpoints.md}) {
    font-size: 20px;
  }
`

const ExplainDetail2 = styled.h5`
  font-family: "Work Sans";
  font-style: normal;

  font-size: calc(0.7vw + 0.5rem);
  @media (max-width: ${breakpoints.md}) {
    font-size: 12px;
  }
`
const ExplainDiv = styled.div`
  margin-left: 2em;
  margin-right: 2em;
  padding-bottom: 0.6rem;
`

const ExplainBlock = ({
  backgroundImage,
  explainDetail,
  title,
  subTitle,
  ctaLabel,
  ctaUrl,
  icon,
  klaviyoFormId,
  klaviyoCta,
}) => {
  const openForm = () => {
    if (typeof window !== "undefined") {
      window._klOnsite = window._klOnsite || []
      window._klOnsite.push(["openForm", klaviyoFormId])
      console.log(klaviyoFormId)
    }
  }
    return (
      <BlockContainer
        style={{
          backgroundImage: 'url("https:' + backgroundImage.file.url + '")',
        }}
      >
        {/* <Background  > */}

        <StyledRow>
          <Rectangle>
            <IconWrapper>
              {" "}
              <Icon src={icon.file.url} />
            </IconWrapper>

            <SubTitle>{subTitle}</SubTitle>
            <Title>{title}</Title>
            <ExplainDiv>
              {explainDetail &&
                explainDetail.map((block, index) => {
                  return (
                    <div style={{ marginBottom: "1vw" }}>
                      <ExplainDetail
                        style={{
                          color:
                            index === 0
                              ? "#824148"
                              : index === 1
                              ? "#EB9CC3"
                              : index === 2
                              ? "#DCCB49"
                              : "#319AD5",
                        }}
                      >
                        {block.title}
                      </ExplainDetail>
                      <ExplainDetail2>{block.subTitle}</ExplainDetail2>
                    </div>
                  )
                })}
            </ExplainDiv>

            {ctaLabel && ctaUrl && !klaviyoCta ? (
              <InternalLink to={ctaUrl}>{ctaLabel}</InternalLink>
            ) : (
              <KlaviyoButton onClick={openForm}>{ctaLabel}</KlaviyoButton>
            )}
          </Rectangle>
        </StyledRow>
        {/* </Background> */}
      </BlockContainer>
    )
  }

export default ExplainBlock
